<template>
  <div class="content-container">
    <div key="content" v-if="!loading" class="wrapper">
      <transition name="fadeLR" mode="out-in">
        <div key="first" class="field" v-if="position == 0">
          <ul class="flex-outer">
            <h2>{{ $t("players.introduction") }}</h2>
            <div class="btfc-center">
              <button class="flex-button" @click="changePage(1)">
                <font-awesome-icon icon="plus" class="buttonIcon" />
                {{ $t("general.addPlayerButton") }}
              </button>
            </div>
            <!-- <button class="flex-button" @click="changePage(6)">Edit player(s)</button> -->
            <h3 v-if="ifError" style="color: red">{{ ifError }}</h3>
            <form ref="editPlayersForm" @submit.prevent="onEditPlayers">
              <ul class="flex-outer">
                <li
                  class="playerContainer"
                  v-for="player in registeredPlayers"
                  v-bind:key="player.id"
                >
                  <label v-bind:for="player.crypKey"
                    >Player {{ player.id }}
                  </label>
                  <input
                    class="inputBox"
                    @change="changedSettings"
                    ref="inputName"
                    type="text"
                    :id="player.crypKey"
                    :value="player.name"
                  />
                  <div class="btfc-right">
                    <!-- <button
                      type="button"
                      class="flex-button btn-right"
                      @click="
                        showQr({ crypKey: player.crypKey, name: player.name })
                      "
                    >
                      <font-awesome-icon icon="qrcode" class="buttonIcon" />
                      {{ $t("players.shareQrButton") }}
                    </button> -->
                    <button
                      type="button"
                      class="flex-button btn-right"
                      @click="
                        deletePlayer({ crypKey: player.crypKey, id: player.id })
                      "
                    >
                      <font-awesome-icon icon="trash" class="buttonIcon" />
                      {{ $t("players.deleteButton") }}
                    </button>
                  </div>
                </li>
                <li>
                  <div class="btfc-right">
                    <button
                      :disabled="!shouldSave"
                      v-if="savedSettings == 0"
                      class="flex-button"
                      type="submit"
                    >
                      <div v-if="!loading">
                        {{ $t("general.saveChangesButton") }}
                      </div>
                      <div v-else>
                        <div class="loader"></div>
                      </div>
                    </button>
                    <div v-else class="flex-button-container">
                      <font-awesome-icon icon="check" size="2x" />
                    </div>
                  </div>
                </li>
              </ul>
              <hr />
            </form>
          </ul>
        </div>
        <div key="addPlayer1" class="field" v-else-if="position == 1">
          <ul class="flex-outer">
            <h2>{{ $t("general.addPlayerButton") }}</h2>
            <h3 v-html="$t('players.descriptionAdding')"></h3>
            <li>
              <div class="btfc-space-around">
                <h5>{{ $t("players.descriptionQrCode") }}</h5>
                <div style="width: 50%">
                  <button class="flex-button" @click="changePage(2)">
                    {{ $t("players.scanQrButton") }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div class="btfc-space-around">
                <h5>{{ $t("players.descriptionNoQrCode") }}</h5>
                <div style="width: 50%">
                  <button
                    class="flex-button"
                    @click="changePage(5)"
                    v-html="$t('players.noQrButton')"
                  ></button>
                </div>
              </div>
            </li>
            <li>
              <div class="btfc-center">
                <button class="flex-button" @click="changePage(0)">
                  {{ $t("general.goBack") }}
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div key="addPlayer2" class="field" v-else-if="position == 2">
          <ul class="flex-outer">
            <qrScanner class="wrapperScanner"></qrScanner>
            <li>
              <div class="btfc-center">
                <button class="flex-button" @click="changePage(0)">
                  {{ $t("general.goBack") }}
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div key="addPlayer3" class="field" v-else-if="position == 3">
          <form ref="newPlayerSubmitForm" @submit.prevent="addPlayerConfirm">
            <ul class="flex-outer">
              <h3>Player {{ newPlayer.id }} - {{ newPlayer.name }}</h3>
              <h5>{{ newPlayer.address }}</h5>
              <hr />
              <h3 v-if="ifError" style="color: red">{{ ifError }}</h3>
              <li>
                <h5>{{ $t("players.renamePlayer") }}</h5>
                <input
                  type="text"
                  class="inputBox"
                  id="Name"
                  :value="newPlayer.name"
                />
              </li>
              <button class="flex-button" @click="changePage(2)">
                {{ $t("players.scanAgainButton") }}
              </button>
              {{
                $t("general.or")
              }}
              <button class="flex-button" type="submit">
                <div v-if="!loading">{{ $t("general.confirmButton") }}</div>
                <div v-else>
                  <div class="loader"></div>
                </div>
              </button>
            </ul>
          </form>
        </div>
        <div key="addPlayer4" class="field" v-else-if="position == 4">
          <h2>{{ $t("players.addedSuccesfully") }}</h2>
          <h3>{{ $t("players.descriptionAddMore") }}</h3>
          <button class="flex-button" @click="changePage(2)">
            {{ $t("general.yesPlease") }}
          </button>
          <button class="flex-button" @click="moveHome()">
            {{ $t("general.noThanks") }}
          </button>
        </div>
        <div key="addPlayer5" class="field" v-else-if="position == 5">
          <h2>{{ $t("players.addDifferently") }}</h2>
          <form ref="requestPlayerNoQR" @submit.prevent="requestPlayerNoQR()">
            <h3 v-if="ifError" style="color: red">{{ ifError }}</h3>
            <ul class="flex-outer">
              <li>
                <label for="Company">{{ $t("players.whichCompany") }}</label>
                <input
                  type="text"
                  class="inputBox"
                  style="width: 40%"
                  id="Company"
                  v-model="addCompany"
                />
              </li>
              <li>
                <label for="Location">
                  {{ $t("players.whichLocation") }}
                </label>
                <input
                  type="text"
                  class="inputBox"
                  style="width: 40%"
                  id="Location"
                  v-model="addLocation"
                />
              </li>
              <div class="btfc-center">
                <button class="flex-button" @click="changePage(0)">
                  {{ $t("general.goBack") }}
                </button>
                <button
                  :disabled="!shouldSave"
                  class="flex-button"
                  type="submit"
                >
                  {{ $t("general.sentButton") }}
                </button>
              </div>
            </ul>
          </form>
        </div>
        <div key="editPlayer" class="field" v-else-if="position == 6"></div>
        <div key="qrScannerShare" class="field" v-else-if="position == 7">
          <h2>{{ $t("players.shareQRtitle", { name: selectedPname }) }}</h2>
          <vue-qrcode
            :value="selectedPkey"
            :options="{ width: 300, color: { light: '#F7F2F3' } }"
          ></vue-qrcode>
          <br />
          <button class="flex-button" @click="changePage(0)">
            {{ $t("general.goBack") }}
          </button>
        </div>
      </transition>
    </div>
    <div key="loading" v-else class="wrapper">
      <div class="bigLoader">
        <h2 class=""></h2>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import qrScanner from "./elements/qrScanner.vue";
const axios = require("axios");

export default {
  name: "players",
  components: {
    qrScanner,
    VueQrcode,
  },
  data: function () {
    return {
      selectedPname: "",
      selectedPkey: "",
      savedSettings: 0,
      shouldSave: null,
      addCompany: null,
      addLocation: null,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    position() {
      return this.$store.state.playerPage; // 0=menu, 1=addPlayer1st (explain use camera) 2=addPlayer2nd(scan qr) 3=addPlayer3d(editName, confirm) 4=succesfully added! 5=addPlayer4d(askForPlayerNoQRCode, 6=editPlayer, 7=qrCodeshare)
    },
    newPlayer() {
      return this.$store.state.newPlayer;
    },
    registeredPlayers() {
      return this.$store.state.user.registeredPlayers;
    },
    ifError() {
      return this.$store.state.error;
    },
    userInfo() {
      return this.$store.state.user;
    },
  },
  methods: {
    changePage(payload) {
      this.clearError();
      this.shouldSave = null;
      if (this.$store.getters.amountRegisteredPlayers == 0 && payload == 0) {
        payload = 1;
      }
      this.$store.commit("changePlayerPage", payload);
    },
    moveHome() {
      this.$router.push("/home");
    },
    changeError(payload) {
      this.$store.commit("changeError", payload);
    },
    clearError() {
      this.$store.commit("clearError");
    },
    setNewPlayer() {
      this.$store.commit("newPlayer", []);
    },
    addPlayerConfirm() {
      if (this.$refs.newPlayerSubmitForm.Name.value == "") {
        this.changeError(this.$i18n.t("general.cantBeEmpty"));
      } else {
        this.$store.dispatch("addNewPlayer", {
          name: this.$refs.newPlayerSubmitForm.Name.value,
        });
        this.$store.commit("newPlayer", []);
        this.changePage(4);
      }
    },
    onEditPlayers() {
      let result = Object.values(this.$refs.inputName).map((a) => [
        a.value,
        a.id,
      ]);
      let values = Object.values(this.$refs.inputName).map((a) => a.value);
      if (values.includes(undefined) || values.includes("")) {
        this.changeError(this.$i18n.t("general.cantBeEmpty"));
      } else {
        if (values == undefined && values == null) {
          this.changeError(this.$i18n.t("general.cantBeEmpty"));
        }
        this.$store.dispatch("editPlayerList", { input: result });
        this.savedSettings = 1;
        this.shouldSave = null;
      }
    },
    changedSettings() {
      this.savedSettings = 0;
      this.shouldSave = 1;
    },
    deletePlayer(payload) {
      this.$modal.show("dialog", {
        text: this.$i18n.t("players.deleteQuestion", { playerID: payload.id }),
        buttons: [
          {
            title: this.$i18n.t("general.close"),
            default: true,
          },
          {
            title: this.$i18n.t("general.yesIAm"),
            handler: () => {
              this.$modal.hide("dialog");
              this.$store.dispatch("deletePlayer", payload).then(() => {
                if (this.$store.getters.amountRegisteredPlayers == 1) {
                  this.changePage(1);
                }
              });
            },
          },
        ],
      });
    },
    // showQr(payload) {
    //   this.selectedPname = payload.name;
    //   this.selectedPkey =
    //     "https://gpmrating.com/addPlayerQR/" + payload.crypKey;
    //   this.changePage(7);
    // },
    requestPlayerNoQR() {
      if (this.ifCompletedInfo()) {
        this.clearError();
        // execute function by sending email
        var data = {
          fullName: this.userInfo.firstname + " " + this.userInfo.lastname,
          company: this.addCompany,
          location: this.addLocation,
          userID: this.userInfo.id,
          alreadyRegistered: this.registeredPlayers,
          email: this.$store.getters.email,
        };

        const getResponse = () => {
          try {
            this.$store.commit("setLoading", true);
            return axios.post(
              process.env.VUE_APP_APIURL + "/mail/sentRequestForPlayer",
              data
            );
          } catch (error) {
            console.error(error);
          }
        };

        const sentResponse = async () => {
          getResponse()
            .then((response) => {
              if (response.data == "ok") {
                this.$store.commit("setLoading", false);
                this.$store.commit("setNotification", {
                  show: true,
                  icon: "success",
                  title: this.$i18n.t("players.sentRequest"),
                });
                this.changePage(0);
              } else {
                this.$store.commit("setLoading", false);
                this.$store.commit("setNotification", {
                  show: true,
                  icon: "error",
                  title: this.$i18n.t("players.fillInDetailed"),
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        };

        sentResponse();
      } else {
        this.changeError("Please fill in all the necessary items.");
      }
    },
    ifCompletedInfo() {
      if (
        this.addCompany !== null &&
        this.addCompany !== "" &&
        this.addLocation !== null &&
        this.addLocation !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.clearError();
    this.setNewPlayer([]);
  },
  created() {
    if (Object.keys(this.registeredPlayers).length === 0) {
      this.changePage(1);
    } else {
      this.changePage(0);
    }
  },
  watch: {
    addCompany() {
      this.shouldSave = this.ifCompletedInfo() ? 1 : null;
    },
    addLocation() {
      this.shouldSave = this.ifCompletedInfo() ? 1 : null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.playerContainer {
  background: rgba(0, 0, 0, 0.1);
  padding: 7px 7px;
  border: 0;
  border-radius: 10px;
  margin-top: 10px !important;
}
</style>
